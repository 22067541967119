import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Config {
    public static CODES = {
        HTTP_OK: 200,
        HTTP_TOO_MANY_REQUESTS: 429,
        HTTP_UNAUTHORIZED: 401,
        HTTP_INTERNAL_SERVER_ERROR: 500,
        HTTP_CONFLICT: 409,
        HTTP_UNPROCESSABLE_ENTITY: 422,
        HTTP_NOT_FOUND: 404
    };

	public static API_ENDPOINT = document.querySelector("meta[name='api-end-point']")['content'] + '/api/';

	public static generateUrl = function(partialUrl, methodName){

        return {
                    url: this.API_ENDPOINT + partialUrl,
                    method: methodName
        };
    };

    public static getCurrentOrigin() {
        const parsedUrl = new URL(window.location.href);

        return parsedUrl.origin;
    }

	public static API = {
        /**
         * auth api
         */

        login: Config.generateUrl('auth/login', 'POST'),
        logout: Config.generateUrl('auth/logout', 'GET'),
        permissionKeys: Config.generateUrl('auth/permission-keys', 'GET'),

        customerList: Config.generateUrl('customer/list', 'POST'),
        customerAdd: Config.generateUrl('customer/add', 'POST'),
        customerEdit: function(id: number) {
            return Config.generateUrl('customer/edit/' + id, 'GET');
        },
        customerUpdate: function(id: number) {
            return Config.generateUrl('customer/update/' + id, 'PUT');
        },
        customerDelete: function(id: number) {
            return Config.generateUrl('customer/delete/' + id, 'DELETE');
        },

        supplierList: Config.generateUrl('supplier/list', 'POST'),
        supplierAdd: Config.generateUrl('supplier/add', 'POST'),
        supplierEdit: function(id: number) {
            return Config.generateUrl('supplier/edit/' + id, 'GET');
        },
        supplierUpdate: function(id: number) {
            return Config.generateUrl('supplier/update/' + id, 'PUT');
        },
        supplierDelete: function(id: number) {
            return Config.generateUrl('supplier/delete/' + id, 'DELETE');
        },

        itemList: Config.generateUrl('item/list', 'POST'),
        itemAdd: Config.generateUrl('item/add', 'POST'),
        itemEdit: function(id: number) {
            return Config.generateUrl('item/edit/' + id, 'GET');
        },
        itemUpdate: function(id: number) {
            return Config.generateUrl('item/update/' + id, 'PUT');
        },
        itemDelete: function(id: number) {
            return Config.generateUrl('item/delete/' + id, 'DELETE');
        },

        employeeList: Config.generateUrl('employee/list', 'POST'),
        employeeAdd: Config.generateUrl('employee/add', 'POST'),
        employeeEdit: function(id: number) {
            return Config.generateUrl('employee/edit/' + id, 'GET');
        },
        employeeUpdate: function(id: number) {
            return Config.generateUrl('employee/update/' + id, 'PUT');
        },
        employeeDelete: function(id: number) {
            return Config.generateUrl('employee/delete/' + id, 'DELETE');
        },

        casualEmployeeList: Config.generateUrl('casual-employee/list', 'POST'),
        casualEmployeeAdd: Config.generateUrl('casual-employee/add', 'POST'),
        casualEmployeeQuickAdd: Config.generateUrl('casual-employee/quick-add', 'POST'),
        casualEmployeeEdit: function(id: number) {
            return Config.generateUrl('casual-employee/edit/' + id, 'GET');
        },
        casualEmployeeUpdate: function(id: number) {
            return Config.generateUrl('casual-employee/update/' + id, 'PUT');
        },
        casualEmployeeDelete: function(id: number) {
            return Config.generateUrl('casual-employee/delete/' + id, 'DELETE');
        },

        roleList: Config.generateUrl('role/list', 'POST'),
        roleAdd: Config.generateUrl('role/add', 'POST'),
        roleEdit: function(id: number) {
            return Config.generateUrl('role/edit/' + id, 'GET');
        },
        roleUpdate: function(id: number) {
            return Config.generateUrl('role/update/' + id, 'PUT');
        },
        roleDelete: function(id: number) {
            return Config.generateUrl('role/delete/' + id, 'DELETE');
        },

        projectAttachmentList: function(projectId: number) {
            return Config.generateUrl('project-attachment/list/' + projectId, 'POST');
        },
        projectAttachmentAdd: function(projectId: number) {
            return Config.generateUrl('project-attachment/add/' + projectId, 'POST');
        },
        projectAttachmentDelete: function(projectAttachmentId: number) {
            return Config.generateUrl('project-attachment/delete/' + projectAttachmentId, 'DELETE');
        },

        projectList: Config.generateUrl('project/list', 'POST'),
        projectAdd: Config.generateUrl('project/add', 'POST'),
        projectEdit: function(id: number) {
            return Config.generateUrl('project/edit/' + id, 'GET');
        },
        projectUpdate: function(id: number) {
            return Config.generateUrl('project/update/' + id, 'PUT');
        },
        projectDelete: function(id: number) {
            return Config.generateUrl('project/delete/' + id, 'DELETE');
        },

        truckList: Config.generateUrl('truck/list', 'POST'),
        truckAdd: Config.generateUrl('truck/add', 'POST'),
        truckEdit: function(id: number) {
            return Config.generateUrl('truck/edit/' + id, 'GET');
        },
        truckUpdate: function(id: number) {
            return Config.generateUrl('truck/update/' + id, 'PUT');
        },
        truckDelete: function(id: number) {
            return Config.generateUrl('truck/delete/' + id, 'DELETE');
        },

        driverAllocationList: Config.generateUrl('driver-allocation/list', 'POST'),
        driverAllocationAdd: Config.generateUrl('driver-allocation/add', 'POST'),
        driverAllocationEdit: function(id: number) {
            return Config.generateUrl('driver-allocation/edit/' + id, 'GET');
        },
        driverAllocationUpdate: function(id: number) {
            return Config.generateUrl('driver-allocation/update/' + id, 'PUT');
        },
        driverAllocationDelete: function(id: number) {
            return Config.generateUrl('driver-allocation/delete/' + id, 'DELETE');
        },

        siteInstructionList: Config.generateUrl('site-instruction/list', 'POST'),
        siteInstructionAdd: Config.generateUrl('site-instruction/add', 'POST'),
        siteInstructionEdit: function(id: number) {
            return Config.generateUrl('site-instruction/edit/' + id, 'GET');
        },
        siteInstructionUpdate: function(id: number) {
            return Config.generateUrl('site-instruction/update/' + id, 'PUT');
        },
        siteInstructionDelete: function(id: number) {
            return Config.generateUrl('site-instruction/delete/' + id, 'DELETE');
        },
        siteInstructionPDFExport: function(id: number) {
            return Config.generateUrl('site-instruction/export/pdf/' + id, 'GET');
        },

        siteInductionAdd: Config.generateUrl('site-induction/generate-form-links', 'POST'),
        siteInductionList: Config.generateUrl('site-induction/list', 'POST'),
        siteInductionPDFExport: function(id: number) {
            return Config.generateUrl('site-induction/export/pdf/' + id, 'GET');
        },

        getPreFormDataForSiteInductionForm(token: string) {
            return Config.generateUrl('site-induction/pre-form-data/' + token, 'GET');
        },

        fillSiteInductionForm(token: string) {
            return Config.generateUrl('site-induction/fill-form/' + token, 'POST');
        },

        siteInductionDelete(id: number) {
            return Config.generateUrl('site-induction/delete/' + id, 'DELETE');
        },

        machinePrestartCheckList: Config.generateUrl('machine-prestart-check/list', 'POST'),
        machinePrestartCheckAdd: Config.generateUrl('machine-prestart-check/add', 'POST'),
        machinePrestartCheckEdit: function(id: number) {
            return Config.generateUrl('machine-prestart-check/edit/' + id, 'GET');
        },
        machinePrestartCheckUpdate: function(id: number) {
            return Config.generateUrl('machine-prestart-check/update/' + id, 'PUT');
        },
        machinePrestartCheckDelete: function(id: number) {
            return Config.generateUrl('machine-prestart-check/delete/' + id, 'DELETE');
        },
        machinePrestartCheckPDFExport: function(id: number) {
            return Config.generateUrl('machine-prestart-check/export/pdf/' + id, 'GET');
        },

        dailySiteReportList: Config.generateUrl('daily-site-report/list', 'POST'),
        dailySiteReportAdd: Config.generateUrl('daily-site-report/add', 'POST'),
        dailySiteReportEdit: function(id: number) {
            return Config.generateUrl('daily-site-report/edit/' + id, 'GET');
        },
        dailySiteReportUpdate: function(id: number) {
            return Config.generateUrl('daily-site-report/update/' + id, 'PUT');
        },
        dailySiteReportDelete: function(id: number) {
            return Config.generateUrl('daily-site-report/delete/' + id, 'DELETE');
        },
        dailySiteReportPDFExport: function(id: number) {
            return Config.generateUrl('daily-site-report/export/pdf/' + id, 'GET');
        },

        truckMovementList: Config.generateUrl('truck-movement/list', 'POST'),
        truckMovementAdd: Config.generateUrl('truck-movement/add', 'POST'),
        truckMovementEdit: function(id: number) {
            return Config.generateUrl('truck-movement/edit/' + id, 'GET');
        },
        truckMovementUpdate: function(id: number) {
            return Config.generateUrl('truck-movement/update/' + id, 'PUT');
        },
        truckMovementDelete: function(id: number) {
            return Config.generateUrl('truck-movement/delete/' + id, 'DELETE');
        },
        truckMovementPDFExport: function(id: number) {
            return Config.generateUrl('truck-movement/export/pdf/' + id, 'GET');
        },

        dailyTruckDocketList: Config.generateUrl('daily-truck-docket/list', 'POST'),
        dailyTruckDocketAdd: Config.generateUrl('daily-truck-docket/add', 'POST'),
        dailyTruckDocketEdit: function(id: number) {
            return Config.generateUrl('daily-truck-docket/edit/' + id, 'GET');
        },
        dailyTruckDocketUpdate: function(id: number) {
            return Config.generateUrl('daily-truck-docket/update/' + id, 'PUT');
        },
        dailyTruckDocketDelete: function(id: number) {
            return Config.generateUrl('daily-truck-docket/delete/' + id, 'DELETE');
        },
        dailyTruckDocketPDFExport: function(id: number) {
            return Config.generateUrl('daily-truck-docket/export/pdf/' + id, 'GET');
        },

        dailyTruckSafetyList: Config.generateUrl('daily-truck-safety/list', 'POST'),
        dailyTruckSafetyAdd: Config.generateUrl('daily-truck-safety/add', 'POST'),
        dailyTruckSafetyEdit: function(id: number) {
            return Config.generateUrl('daily-truck-safety/edit/' + id, 'GET');
        },
        dailyTruckSafetyUpdate: function(id: number) {
            return Config.generateUrl('daily-truck-safety/update/' + id, 'PUT');
        },
        dailyTruckSafetyDelete: function(id: number) {
            return Config.generateUrl('daily-truck-safety/delete/' + id, 'DELETE');
        },
        dailyTruckSafetyPDFExport: function(id: number) {
            return Config.generateUrl('daily-truck-safety/export/pdf/' + id, 'GET');
        },

        geolocationLogList: Config.generateUrl('geolocation-log/list', 'POST'),

        dayOptionsList: Config.generateUrl('shared/day-list', 'GET'),
        projectOptionsList: Config.generateUrl('shared/project-list-for-select-options', 'GET'),
        projectOptionsListWithAllOption: Config.generateUrl('shared/project-list-with-all-option-for-select-options', 'GET'),
        roleOptionsList: Config.generateUrl('shared/role-list-for-select-options', 'GET'),
        truckOptionsList: Config.generateUrl('shared/truck-list-for-select-options', 'GET'),
        driverOptionsList: Config.generateUrl('shared/driver-list-for-select-options', 'GET'),
        operatorOptionsList: Config.generateUrl('shared/operator-list-for-select-options', 'GET'),
        foremanOptionsList: Config.generateUrl('shared/foreman-list-for-select-options', 'GET'),
        machineOptionsList: Config.generateUrl('shared/machine-list-for-select-options', 'GET'),
        truckTypeOptionsList: Config.generateUrl('shared/truck-type-list-for-select-options', 'GET'),
        materialTypeOptionsList: Config.generateUrl('shared/material-type-list-for-select-options', 'GET'),
        equipmentMaterialItemOptionsList: Config.generateUrl('shared/equipment-material-item-list-for-select-options', 'GET'),
        permissionGroupWithPermissions: Config.generateUrl('shared/permission-group-with-permissions', 'GET'),
        customerOptionsList: Config.generateUrl('shared/customer-list-for-select-options', 'GET'),
        itemTypeOptionsList: Config.generateUrl('shared/item-type-list-for-select-options', 'GET'),
        supplierOptionsList: Config.generateUrl('shared/supplier-list-for-select-options', 'GET'),
        registeredEmployeeOptionsList: Config.generateUrl('shared/registered-employees-for-select-options', 'GET'),
        casualEmployeeOptionsList: Config.generateUrl('shared/casual-employees-for-select-options', 'GET'),
        employeeTypeOptionsList: Config.generateUrl('shared/employee-type-list-for-select-options', 'GET'),
        siteInductionFormStatusOptionsList: Config.generateUrl('shared/site-induction-form-status-list-for-select-options', 'GET'),

        assignedDriverOfATruckAsOption(id: number) {
            return Config.generateUrl('shared/assigned-driver-of-a-truck-as-option/' + id, 'GET');
        },

        projectOptionsListOfACustomerWithJobLocation: function(id: number) {
            return Config.generateUrl('shared/project-list-of-a-customer-with-job-location-for-select-options/' + id, 'GET');
        },

        /**
         * dashboard api
         */

        formSubmittedStatusData: Config.generateUrl('dashboard/form-submitted-status-data', 'POST'),
        formSubmittedStatusDetails: Config.generateUrl('dashboard/form-submitted-status-details', 'POST')
	};
}
