import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Auth } from './auth';
import { Observable } from 'rxjs';
import { AlertController, ToastController } from '@ionic/angular';
import { Config } from './config';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class Request {

    headers: any = {}; // for storing headers which are send in the request

    url: string = ''; // url of the request

    method: string = ''; // method of the request

    data: any = {}; // data to be sent in the request

    params: any = {}; // parameters which are to be send in the url

    timeout: number = 10000;

    loginRoute: string = '/app/login';

    successNotification: any = {
      enable: false,
      duration: 1000
    };

    errorAlert: any = {
      enable: false,
      duration: 1000,
      autoClose: true
    };

    reportProgress: boolean = false;

    constructor(
        private auth: Auth,
        private http: HttpClient,
        private alertCtrl: AlertController,
        private toastCtrl: ToastController,
        private router: Router) {
    }

    /**
     * for sending request with the jwt token
     */
    withToken() {

        this.headers['Authorization'] = this.getAuthHeader();

        return this;
    }

    withSuccessNotification(options: any) {

      let settings = options || {};

      settings.enable = (options.enable == undefined) ? this.successNotification.enable : options.enable;
      settings.duration = (options.duration == undefined) ? this.successNotification.duration : options.duration;

      this.successNotification = settings;

      return this;
    }

    withReportProgress() {
      this.reportProgress = true;

      return this;
    }

    withErrorAlert(options: any) {

      let settings = options || {};

      settings.duration = (options.duration == undefined) ? this.errorAlert.duration : options.duration;
      settings.autoClose = (options.autoClose == undefined) ? this.errorAlert.autoClose : options.autoClose;

      this.errorAlert = settings;

      return this;
    }

    getAuthHeader() {
        
        return 'Bearer ' + this.auth.getAuthToken();
    }
    /**
     * for sending request with the given route, route contains url and method for the request
     * @param  any routeConfig [contains url: 'url for the request', method: 'method for the request']
     * @return Object
     */
    withAPI(routeConfig: any) {

        this.url = routeConfig.url;
        this.method = routeConfig.method;

        return this;
    }

    /**
     * for sending request with the given parameters the parameters are received in the js object form and are converted in to the http query
     * @param  any params {page: 2, search: 'hello'}
     * @return Object
     */
    withParams(params: any) {

      this.params = params;

      return this;
    }

    /**
     * for sending request with the data passed
     * @param  Object data [will be mostly form data]
     * @return Object
     */
    withData(data) {

        this.data = data;

        return this;
    }

    withTimeout(time) {

        this.timeout = time;

        return this;
    }

    isPutMethod() {
      return this.method == 'PUT';
    }

    /**
     * finally sends the request
     * @return Object [promise of the xhr object]
     */
    async send() {

      let method = this.method;
      let params = this.params;

      if(this.isPutMethod()) {
        method = 'POST';
        params['_method'] = 'PUT';
      }

      let req = new HttpRequest(method, this.url, this.data, { params: new HttpParams({ fromObject: params }), headers: new HttpHeaders(this.headers) });

      let thisRef = this;

      return new Promise(function(resolve, reject) {
              
        thisRef.http
               .request(req)
               .toPromise()
               .then(
                    data => {

                        let resolveData: any = {
                            statusCode: data['status'],
                            status: data['body']['status'],
                            message: data['body']['message'] || null,
                            data: data['body']['data']
                        };

                        if(thisRef.successNotification.enable && resolveData.message != null) {
                          if(resolveData.statusCode == Config.CODES.HTTP_OK)
                              thisRef.showSuccessNotification(resolveData.message);
                        }

                        resolve(resolveData);
                    },
                    error => {

                      let rejectData: any = {
                            statusCode: error['status'],
                            status: error['error']['status'] || error['statusText'],
                            message: error['error']['message'] || null,
                            data: error['error']['data'] || null
                        };

                        thisRef.errorResponseHandle(rejectData);

                        reject(rejectData);
                    }
                )
                .finally(
                    () => {
                        thisRef.resetRequestProperties();
                    }
                );
      });
    }

    coreSend(): Observable<HttpEvent<any>> {

      let method = this.method;
      let params = this.params;

      if(this.isPutMethod()) {
        method = 'POST';
        params['_method'] = 'PUT';
      }

      let req = new HttpRequest(method, this.url, this.data, { reportProgress: this.reportProgress, params: new HttpParams({ fromObject: params }), headers: new HttpHeaders(this.headers) });

      return this.http.request(req);
    }

    /**
     * handles the response error, auth error, validation error or connection error
     */
    errorResponseHandle(e) {
        
        switch(e.statusCode) {
            case Config.CODES.HTTP_NOT_FOUND:
            case Config.CODES.HTTP_INTERNAL_SERVER_ERROR: {

                this.showServerError(e);
            }
            break;

            case Config.CODES.HTTP_CONFLICT: {

              if(this.errorAlert.enable && e.message != null) {
                this.showErrorAlert(e.message);
              }
            }
            break;

            case Config.CODES.HTTP_UNAUTHORIZED: {
              if(e.message == null)
                this.showErrorAlert("Unauthorized!!");
              else
                this.showErrorAlert(e.message);

              this.router.navigate([this.loginRoute]);
            }
            break;

            case Config.CODES.HTTP_TOO_MANY_REQUESTS: {
              if(e.message == null)
                this.showErrorAlert("Too Many Requests!!");
              else
                this.showErrorAlert(e.message);
            }
            break;

            default: {
                this.showConnectionError();
            }
            break;
        }
    }

    async showSuccessNotification(message) {
      const toast = await this.toastCtrl.create({
        message: message,
        duration: 1000
      });

      toast.present();
    }

    async showErrorAlert(message) {
      let thisRef = this;
      let autoClose = this.errorAlert.autoClose;

      const alert = await this.alertCtrl.create({
        message: message,
        buttons: ['Ok']
      });

      let presentAlert = alert.present();

      if(autoClose) {
        presentAlert.then(
          () => {
            setTimeout(function() {
              alert.dismiss();
            }, thisRef.errorAlert.duration);
          }
       );
      }
    }

    async showServerError(data) {

        const alert = await this.alertCtrl.create({
          header: 'Error',
          subHeader: '',
          message: 'Error while processing the request!!',
          buttons: ['Ok']
        });

        await alert.present();
    }

    /**
     * displays the connection error in popup
     */
    showConnectionError() {

        // $notification.withDanger()

        // .withMessage('Connection Error!!')
        // .onShown(thisRef.authOrConnectionErrorCallBack)
        // .show();
    }

    /**
     * sets the Content-type and accept headers which are required while sending data in put or post request
     */
    setJsonTypeHeaders() {

        this.headers['Content-Type'] = 'application/json';
        this.headers['accept'] = 'application/json';
    }

    /**
     * return the headers
     * @return Object
     */
    getHeaders() {

        return this.headers;
    }
   
    /**
     * do something after auth error popup or connection error popup
     */
    authOrConnectionErrorCallBack() {

        // $state.go('logout');             
    }

    /**
     * resets all the properties of this object
     */
    resetRequestProperties() {

        this.headers = {};

        this.url = "";

        this.method = "";

        this.data = null;
        this.params = {};
        this.timeout = 10000;
        this.successNotification = {
          enable: false,
          duration: 1000
        };
    
        this.errorAlert = {
          enable: false,
          duration: 500,
          autoClose: true
        };
;
    }
}