import { Injectable, Output, EventEmitter } from '@angular/core';
import { EmployeeProfile } from '../../modules/auth/models/employee-profile.model';

@Injectable({
  providedIn: 'root',
})
export class Auth {
    private storageKeyForToken = 'auth-token';
    private storageKeyForEmployee = 'auth-employee';
    private storageKeyForPermissions = 'employee-permissions';

    @Output() onLoginStatusChanged = new EventEmitter<boolean>();

    constructor() {

    }

    isLoggedIn() {
        return (this.getAuthToken() != null);
    }

    setAuthToken(token: string) {
        localStorage.setItem(this.storageKeyForToken, token);
    }

    getAuthToken() {
        return localStorage.getItem(this.storageKeyForToken);
    }

    setLoggedInEmployee(employee: EmployeeProfile) {
        localStorage.setItem(this.storageKeyForEmployee, JSON.stringify(employee));

        this.loginStatusChanged();
    }

    removeAuthToken() {
        localStorage.removeItem(this.storageKeyForToken);
    }

    removeLoggedInEmployee() {
        localStorage.removeItem(this.storageKeyForEmployee);

        this.loginStatusChanged()
    }

    getLoggedInEmployee(): EmployeeProfile {
        return new EmployeeProfile().deserialize(JSON.parse(localStorage.getItem(this.storageKeyForEmployee)));
    }

    loginStatusChanged() {
        this.onLoginStatusChanged.emit(this.isLoggedIn());
    }

    isPermissionsLoaded() {
        return (this.getEmployeePermissionsFromLocalStorage() != null);
    }

    getEmployeePermissionsFromLocalStorage() {
        return JSON.parse(localStorage.getItem(this.storageKeyForPermissions));
    }

    setEmployeePermissions(permissions: string[]) {
        permissions.push('common');
        localStorage.setItem(this.storageKeyForPermissions, JSON.stringify(permissions));
    }

    removeEmployeePermissions() {
        localStorage.removeItem(this.storageKeyForPermissions);
    }
}