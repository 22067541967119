import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginPage } from './modules/auth/pages/login/login.page';
import { AuthLayout } from './modules/auth/layout/auth.layout';
import { SiteInductionFormLayout } from './modules/site-induction-form/layout/site-induction-form.layout';
import { SideMenuLayout } from './modules/side-menu/layout/side-menu.layout';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/app/login',
    pathMatch: 'full'
  },
  {
    path: 'app',
    component: AuthLayout,
    loadChildren: () => import('./modules/auth/auth.module')
                        .then( m => m.AuthModule )
  },
  {
    path: '',
    component: SideMenuLayout,
    loadChildren: () => import('./modules/side-menu/side-menu.module')
                        .then( m => m.SideMenuModule )
  },
  {
    path: 'site-induction-form',
    component: SiteInductionFormLayout,
    loadChildren: () => import('./modules/site-induction-form/site-induction-form.module')
                        .then( m => m.SiteInductionFormModule )
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutes {}
