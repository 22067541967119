export class EmployeeProfile {
	email: string;
	first_name: string;
	last_name: string;
	start_date: Date;
	end_date: Date;
	mobile_number: string;
	role_name: string;
	role_id: number;

	deserialize(input: any): EmployeeProfile {
	  Object.assign(this, input);

	  return this;
	}

	fullName() {
		return this.first_name + ' ' + this.last_name;
	}
}
