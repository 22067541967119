import { Injectable } from '@angular/core';
import { Config } from '../core/config';
import { Request } from '../core/request';
import { Auth } from '../core/auth';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
	constructor(
		private auth: Auth,
		private request: Request,
		private ngxPermissionService: NgxPermissionsService
	) { }

	async getPermissionsOfLoggedInEmployee() {
		return this.request

		.withToken()
		.withAPI(Config.API.permissionKeys)
		.send();
	}

	getPermissionGroupWithPermissions() {
		return this.request

		.withToken()
		.withAPI(Config.API.permissionGroupWithPermissions)
		.send();
	}

	isPermissionsLoadedInNgxPermissions() {
		return Object.keys(this.ngxPermissionService.getPermissions()).length > 0;
	}

	async loadAndStorePermissionsOfLoggedInEmployee() {
		if(this.auth.isPermissionsLoaded()) {
			if(!this.isPermissionsLoadedInNgxPermissions())
				this.ngxPermissionService.loadPermissions(this.auth.getEmployeePermissionsFromLocalStorage());
			
			return await null;
		} else {
			return this.getPermissionsOfLoggedInEmployee()

			.then(
				response => {
					this.auth.setEmployeePermissions(response['data']);
					this.ngxPermissionService.loadPermissions(response['data']);
				}
			);
		}
	}

	reloadPermissionsOfLoggedInEmployee() {
		this.removePermissionsOfLoggedInEmployee();
		this.loadAndStorePermissionsOfLoggedInEmployee();
	}

	removePermissionsOfLoggedInEmployee() {
		this.ngxPermissionService.flushPermissions();
		this.auth.removeEmployeePermissions();
	}

	flushPermissions() {

		this.ngxPermissionService.flushPermissions();
	}
}
