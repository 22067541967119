import { NgModule, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import { AppLayout } from './layout/app.layout';
import { AuthLayout } from './modules/auth/layout/auth.layout';
import { SideMenuLayout } from './modules/side-menu/layout/side-menu.layout';
import { SiteInductionFormLayout } from './modules/site-induction-form/layout/site-induction-form.layout';
import { AppRoutes } from './app.routes';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [AppLayout, AuthLayout, SideMenuLayout, SiteInductionFormLayout],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    HttpClientModule,
    AppRoutes,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppLayout]
})
export class AppModule {
  static injector: Injector;

  /**
   * for accessing the singleton objects without injecting into the constructor
   * @param {Injector} injector [description]
   */
  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
