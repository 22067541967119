import { Component } from '@angular/core';

import { Auth } from '../../../shared/core/auth';
import { Router } from '@angular/router';
import { PermissionService as PermissionAPIService } from '../../../shared/services/permission.service';
import { EmployeeProfile } from '../../auth/models/employee-profile.model';

@Component({
  selector: 'side-menu-layout',
  templateUrl: 'side-menu.layout.html',
  styleUrls: ['side-menu.layout.scss']
})
export class SideMenuLayout {
  public selectedMenuIndex = 0;
  public iconPostFix = '';
  public menus = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'speedometer',
      permission: ['common']
    },
    {
      title: 'Site Instruction',
      url: '/site-instruction/list',
      icon: 'information-circle',
      permission: [
        'site-instruction.view',
        'site-instruction.edit',
        'site-instruction.delete'
      ]
    },
    {
      title: 'Machine Prestart Check',
      url: '/machine-prestart-check/list',
      icon: 'checkmark-circle',
      permission: [
        'machine-prestart-check.view',
        'machine-prestart-check.edit',
        'machine-prestart-check.delete'
      ]
    },
    {
      title: 'Daily Site Report',
      url: '/daily-site-report/list',
      icon: 'bookmarks',
      permission: [
        'daily-site-report.view',
        'daily-site-report.edit',
        'daily-site-report.delete'
      ]
    },
    {
      title: 'Daily Truck Docket',
      url: '/daily-truck-docket/list',
      icon: 'clipboard',
      permission: [
        'daily-truck-docket.view',
        'daily-truck-docket.edit',
        'daily-truck-docket.delete'
      ]
    },
    {
      title: 'Daily Truck Safety',
      url: '/daily-truck-safety/list',
      icon: 'medkit',
      permission: [
        'daily-truck-safety.view',
        'daily-truck-safety.edit',
        'daily-truck-safety.delete'
      ]
    },
    {
      title: 'Truck Movement',
      url: '/truck-movement/list',
      icon: 'bus',
      permission: [
        'truck-movement.view',
        'truck-movement.edit',
        'truck-movement.delete'
      ]
    },
    {
      title: 'Site Induction',
      url: '/site-induction/list',
      icon: 'checkmark-done-circle',
      permission: [
        'site-induction.view',
        'site-induction.edit',
        'site-induction.delete'
      ]
    },
    {
      title: 'Driver Allocation',
      url: '/driver-allocation/list',
      icon: 'person-add',
      permission: [
        'driver-allocation.view',
        'driver-allocation.edit',
        'driver-allocation.delete'
      ]
    },
    {
      title: 'Geolocation Logs',
      url: '/geolocation-log/list',
      icon: 'location',
      permission: ['geolocation-log.view']
    },
    {
      title: 'Setting',
      url: '/settings',
      icon: 'settings',
      permission: [
        'role.view',
        'role.edit',
        'role.delete',

        'item.view',
        'item.edit',
        'item.delete',

        'truck.view',
        'truck.edit',
        'truck.delete',

        'project.view',
        'project.edit',
        'project.delete',

        'supplier.view',
        'supplier.edit',
        'supplier.delete',

        'customer.view',
        'customer.edit',
        'customer.delete',

        'employee.view',
        'employee.edit',
        'employee.delete',

        'casual-employee.view',
        'casual-employee.edit',
        'casual-employee.delete'
      ]
    }
  ];

  public employeeProfile: EmployeeProfile;

  constructor(
    private auth: Auth,
    private router: Router,
    private permissionAPIService: PermissionAPIService
  ) {
    this.setCurrentEmployeeProfile(); // runs only first time i.e. when the view is created; view is created only once
    this.detectModeAndSetIconPostFix();
    this.permissionAPIService.loadAndStorePermissionsOfLoggedInEmployee();
  }

  /**
   * runs when the view is loaded i.e. each time when the user logs in
   */
  ionViewWillEnter() {
    this.setCurrentEmployeeProfile();
  }

  setCurrentEmployeeProfile() {
    this.employeeProfile = this.auth.getLoggedInEmployee();
  }

  logout() {
    this.router.navigateByUrl('app/logout');
  }

  detectModeAndSetIconPostFix() {
    let htmlDOMEle = document.getElementsByTagName("html")[0];

    if(htmlDOMEle.getAttribute("mode") == 'ios')
      this.iconPostFix = '-outline';
  }
}
